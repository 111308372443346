import React from 'react'

const Color = ({color, hex, RGB}) => {
    return (
        <div id="red-basket-color" className="flex justify-center items-center flex-col" >
           <div className="color-shape" style={{background: `${color}`}} ></div> 
           <div className="mt-2" >
              <p className="text-xs mt-0.5" >Hex: {hex}</p>
              <p className="text-xs py-0.5" >RGB: {RGB}</p>
           </div>
           
        </div>
    )
}

export default Color

