import React from 'react'
import vizitka from "../../../../assets/na-caji/vizitka4.jpg"

const Vizitka = () => {
    return (
        <div id="na-caji-vizitka" className="" >
           <img src={vizitka} alt="img" className="bg w-full" />   
        </div>
    )
}

export default Vizitka
