import React from 'react'

const Colory = () => {
    return (
        <div id="na-caji-colory" className="py-7 md:py-12" >
           <div id="colory" className="flex justify-center items-center" >
             <div  ></div>
             <div  ></div>
             <div  ></div>
             <div  ></div>
           </div>      
           <div id="spac" className="mt-12 xl:mt-20" >
             <h4 className="te1 text-secondary2 font-serif text-sm lg:text-base lg:font-semibold text-center" >Pf regal Finesse Pro</h4>
             <h2 className="te2 text-center font-medium text-lg  md:text-2xl lg:text-3xl font-serif tracking-widest mt-4 md:mt-5" style={{letterSpacing: "9px"}} >abcdefghijklmno<br/>prstuvwxyz</h2> 
           </div>   
           <div className="mt-8 xl:mt-16"  >
             <p className="te3 text-center text-3xs lg:text-2xs xl:text-xs font-sans" >Zona pro</p>  
             <h2 className="te4 text-center text-3xs lg:text-xs xl:text-sm font-sans mt-4" style={{letterSpacing: "3px"}} >ABCDEFGHIJKLMNO<br />PRSTUVWXYZ</h2> 
           </div>   
        </div>
    )
}

export default Colory
