import React, {useEffect} from 'react'
import Navbar from "../../Navbar/Navbar"
import Main from "./content/Main"
import logo from "../../../assets/logo3.svg"
import Text from "./content/Text"
import Shapes from './content/Shapes'
import Colory from './content/Colory'
import Patern from './content/Patern'
import Bgs from './content/Bgs'
import Next from "../../Next/Next"
import Footer from "../../Footer/Footer"
import $ from "jquery"
import gsap from "gsap"

const Red_Basket = () => {

    useEffect(() => {
     
        let tl_loading = gsap.timeline()
        tl_loading
          .fromTo("#red-basket-main .logo", {duration: 0.5,autoAlpha: 0,y: 30,ease: "ease-in-out",},{autoAlpha: 1, y:0}, "+=0.5")


          $("#red-basket-colory .t1").addClass("ani_left_50")
          $("#red-basket-colory .t2").addClass("ani_right_50")
          $("#red-basket-colory .t3").addClass("ani_left_50")
          $("#red-basket-patern").addClass("ani_top_70")
          $("#red-basket-bgs .rb1").addClass("ani_top_70")
          $("#red-basket-bgs .rb2").addClass("ani_top_70")
          $("#red-basket-bgs .rb3").addClass("ani_top_70")


        $(window).scroll(function (){
          
          if($("#red-basket-main .logo").length > 0) {
            let real = $(window).height();
            let te1 = $("#red-basket #text .text-prvy").offset().top;
            let te2 = $("#red-basket #text .text-druhy").offset().top;
            let role = $("#red-basket #text .role").offset().top;
            let shape = $("#red-basket-shapes .bg").offset().top;
            let t1 = $("#red-basket-colory .t1").offset().top;
            let t2 = $("#red-basket-colory .t2").offset().top;
            let t3 = $("#red-basket-colory .t3").offset().top;
            let colory = $("#red-basket-colory .gridik-color").offset().top;
            let patern =  $("#red-basket-patern").offset().top;
            let b1 =  $("#red-basket-bgs .rb1").offset().top;
            let b2 =  $("#red-basket-bgs .rb2").offset().top;
            let b3 =  $("#red-basket-bgs .rb3").offset().top;

            if($(window).scrollTop() > te1 - real/1.2) {
                $("#red-basket #text .text-prvy").addClass("top")
            }
            if($(window).scrollTop() > te2 - real/1.2) {
                $("#red-basket #text .text-druhy").addClass("top")
            }
            if($(window).scrollTop() > role - real/1.2) {
                $("#red-basket #text .role").addClass("top")
            }
            if($(window).scrollTop() > shape - real/1.2) {
                $("#red-basket-shapes .bg").addClass("scale")
            }
            if($(window).scrollTop() > t1 - real/1.2) {
                $("#red-basket-colory .t1").addClass("left__right_margin")
            }
            if($(window).scrollTop() > t2 - real/1.2) {
                $("#red-basket-colory .t2").addClass("left__right_margin")
            }
            if($(window).scrollTop() > t3 - real/1.2) {
                $("#red-basket-colory .t3").addClass("left__right_margin")
            }
            if($(window).scrollTop() > colory - real/1.2) {
                $("#red-basket-colory .gridik-color").addClass("opacity")
            }
            if($(window).scrollTop() > patern - real/1.2) {
                $("#red-basket-patern").addClass("top")
            }
            if($(window).scrollTop() > b1 - real/1.2) {
                $("#red-basket-bgs .rb1").addClass("top")
            }
            if($(window).scrollTop() > b2 - real/1.2) {
                $("#red-basket-bgs .rb2").addClass("top")
            }
            if($(window).scrollTop() > b3 - real/1.2) {
                $("#red-basket-bgs .rb3").addClass("top")
            }


          }   

        })

    }, [])

    return (
        <div id="red-basket" >
            <Navbar logo={logo} color={"sal_txt"} work_bold={"bold"} about_bold={"normal"}/>   
            <Main />
            <Text />
            <Shapes />
            <Colory />
            <Patern />
            <Bgs />
            <Next next={"tilia"} previous={"slido"} />
            <Footer cl={"slido"} />
        </div>
    )
}

export default Red_Basket
