import React from 'react'
import logo from "../../../../assets/na-caji/logo.svg"

const Main = () => {
    return (
        <div id="na-caji-main" className="w-full h-screen" >
          <div className="h-full flex justify-center align-center" >
            <img src={logo} alt="logo" className="logo w-40 sm:w-48 md:w-52 lg:w-64 xl:w-80 2xl:w-100" />
          </div>
        </div>
    )
}

export default Main
