import React from 'react'
import TextContent from '../../TextContent'

const Text = () => {

    const text = {
      f_t: "Na čaji is an open community of women podcasters who want to make Slovakia a better place. Each episode features a different interview with a woman about her life and career choices.",
      s_t: "Inspired by different women and their stories, the brand identity emphasizes the authenticity and femininity. A bold color range complements soft illustration.",
      f_st: "Role:",
      f_tt: "Art direction",
      s_st: "Credits:",
      s_tt: "Logo design: Matej Rudinský Illustration assistance: Katarína Krajčovičová",
    }

    return (
        <div id="text" className="" >
          <TextContent 
            f_t={text.f_t}
            s_t={text.s_t}
            f_st={text.f_st}
            f_tt={text.f_tt}
            s_st={text.s_st}
            s_tt={text.s_tt}
          />
        </div>
    )
}

export default Text