import React, {useEffect} from 'react'
import logo from "../../../assets/logo3.svg"
import Navbar from "../../Navbar/Navbar"
import Final from './content/Final'
import Lustre from './content/Lustre'
import Main from "./content/Main"
import Text from "./content/Text"
import TypeLust from './content/TypeLust'
import Be from "./content/Bed"
import Desk from './content/Desk'
import Footer from "../../Footer/Footer"
import Next from '../../Next/Next'
import gsap from "gsap"
import $ from "jquery"


const Tilia = () => {

  useEffect(() => {
    
    let tl_loading = gsap.timeline()
    tl_loading
      .fromTo("#tilia .logo", {duration: 0.5,autoAlpha: 0,y: 30,ease: "ease-in-out",},{autoAlpha: 1, y:0}, "+=0.5")

      $("#tilia-final").addClass("ani_top_70")
      $("#tilia-bed").addClass("ani_top_70")

    $(window).scroll(function (){

      if($("#tilia .logo").length > 0) {

        let real = $(window).height();
        let te1 = $("#tilia .text-prvy").offset().top;
        let te2 = $("#tilia .text-druhy").offset().top;
        let role = $("#tilia .role").offset().top;
        let credits = $("#tilia .credits").offset().top;
        let final = $("#tilia-final").offset().top;
        let lus1 = $("#tilia-lustre .lus1").offset().top;
        let lus2 = $("#tilia-lustre .lus2").offset().top;
        let l1 = $("#tilia-type-luster .l1").offset().top;
        let l2 = $("#tilia-type-luster .l2").offset().top;
        let l3 = $("#tilia-type-luster .l3").offset().top;
        let bed = $("#tilia-bed").offset().top;
        let des1 = $("#tilia-desk .des1").offset().top;
        let des2 = $("#tilia-desk .des2").offset().top;
        let des3 = $("#tilia-desk .des3").offset().top;
        let des4 = $("#tilia-desk .des4").offset().top;


        if($(window).scrollTop() > te1 - real/1.2) {
          $("#tilia .text-prvy").addClass("top")
        }
        if($(window).scrollTop() > te2 - real/1.1) {
          $("#tilia .text-druhy").addClass("top")
        }
        if($(window).scrollTop() > role - real/1.2) {
          $("#tilia .role").addClass("left")
        }
        if($(window).scrollTop() > credits - real/1.2) {
          $("#tilia .credits").addClass("left")
        }
        if($(window).scrollTop() > final - real/1.2) {
          $("#tilia-final").addClass("top")
        }
        if($(window).scrollTop() > lus1 - real/1.2) {
          $("#tilia-lustre .lus1").addClass("top")
        }
        if($(window).scrollTop() > lus2 - real/1.2) {
          $("#tilia-lustre .lus2").addClass("top")
        }
        if($(window).scrollTop() > l1 - real/1.2) {
          $("#tilia-type-luster .l1").addClass("top")
        }
        if($(window).scrollTop() > l2 - real/1.2) {
          $("#tilia-type-luster .l2").addClass("top")
        }
        if($(window).scrollTop() > l3 - real/1.2) {
          $("#tilia-type-luster .l3").addClass("top")
        }
        if($(window).scrollTop() > bed - real/1.3) {
          $("#tilia-bed").addClass("top")
        }
        if($(window).scrollTop() > des1 - real/1.3) {
          $("#tilia-desk .des1").addClass("scale")
        }
        if($(window).scrollTop() > des2 - real/1.3) {
          $("#tilia-desk .des2").addClass("scale")
        }
        if($(window).scrollTop() > des3 - real/1.3) {
          $("#tilia-desk .des3").addClass("scale")
        }
        if($(window).scrollTop() > des4 - real/1.3) {
          $("#tilia-desk .des4").addClass("scale")
        }


      }

    })



  }, [])

    return (
        <div id="tilia" >
          <Navbar logo={logo} color={"sal_txt"} work_bold={"bold"} about_bold={"normal"}/>   
          <Main />  
          <Text />
          <Final />
          <Lustre />
          <TypeLust />
          <Be />
          <Desk />
          <Next next={"simply-salon"} previous={"red-basket"} />
          <Footer cl={"slido"} />
        </div>
    )
}

export default Tilia
