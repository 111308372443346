import React from 'react'
import b from "../../../../assets/tilia/bed.png"

const Bed = () => {
    return (
        <div id="tilia-bed" >
           <img src={b} alt="bed" className="w-full" /> 
        </div>
    )
}

export default Bed
