import React from 'react'
import lust1 from "../../../../assets/tilia/lust-1.png"
import lust2 from "../../../../assets/tilia/lust-2.png"


const Lustre = () => {
    return (
        <div id="tilia-lustre" >
           <div className="relative flex flex-col lg:flex-row lg:justify-between w-10/12 lg:w-auto mx-auto " >
             <img src={lust1} alt="luster1" className="lus1 mt-10 lg:mt-16 lg:w-1/3"/>
             <img src={lust2} alt="luster2" className="lus2 mt-10 lg:mt-16 w-res" />
            </div> 
        </div>
    )
}

export default Lustre
