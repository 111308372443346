import React, { useEffect, useState } from 'react'
import Navbar from '../../Navbar/Navbar'
import logo from "../../../assets/logo3.svg"
import Main from './content/Main'
import About from './content/About'
import Type from './content/Type'
import PhotoFooter from './content/PhotoFooter'
import Footer from "../../Footer/Footer"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import $ from "jquery"
import Next from "../../Next/Next"


const Simply_Salon = () => {


    const [loading, setLoading] = useState(true)
   
    gsap.registerPlugin(ScrollTrigger)
   

    const animations = () => {
      
      let tl_loading = gsap.timeline()
      tl_loading
        .fromTo("#simply-main .logo", {duration: 0.5,autoAlpha: 0,y: 30,ease: "ease-in-out",},{autoAlpha: 1, y:0}, "+=0.5")

      let tl_text = gsap.timeline({
      scrollTrigger: {trigger: "#simply-about",markers: false,start: "top 72%",end: "top 0"
      }
      })
       tl_text
        .from("#simply-about .text-prvy", {duration: 0.7,opacity: 0,y: 35,ease: "ease-in",})
        .from("#simply-about .text-druhy", {duration: 0.7,opacity: 0,y: 35,ease: "ease-in",}, "-=0.5")
        .from("#simply-about .role", {duration: 0.7,opacity: 0,x: 35,ease: "ease-in",}, "-=0.5")
        .from("#simply-about .credits", {duration: 0.7,opacity: 0,x: 35,ease: "ease-in",}, "-=0.5")



     

        let tl_photo = gsap.timeline({
          scrollTrigger: {trigger: "#photo-simply .pho1-simply",markers: false,start: "top 72%",end: "top 0"
          }
        })
        tl_photo
        .from("#photo-simply .pho1-simply", {duration: 0.7,opacity: 0,x: -105,ease: "ease-in",})
        .from("#photo-simply .pho2-simply", {duration: 0.7,opacity: 0,x: 105,ease: "ease-in",}, "-=0.5")



        let tl_type = gsap.timeline({
          scrollTrigger: {trigger: "#simply-type .type",markers: false,start: "top 62%",end: "top 0"
          }
        }) 

        tl_type
        .from("#simply-type .type", {duration: 0.7,opacity: 0,x: 70, ease: "ease-in",}, "-=0.5")
       
        let tl_abeceda = gsap.timeline({
          scrollTrigger: {trigger: "#simply-type .abeceda",markers: false,start: "top 62%",end: "top 0"
          }
        }) 
        tl_abeceda
        .from("#simply-type .abeceda", {duration: 0.7,opacity: 0,x: -70, ease: "ease-in",}, "-=0.5")
        
        let tl_paleta = gsap.timeline({
          scrollTrigger: {trigger: "#simply-type .color-paleta",markers: true,start: "top 62%",end: "top 0"
          }
        }) 
        tl_paleta
         .from("#simply-type .color-paleta", {duration: 0.7,opacity: 0,x: 70, ease: "ease-in",}, "-=0.5")


        let tl_color = gsap.timeline({
          scrollTrigger: {trigger: "#simply-type .color-paleta",markers: true,start: "top 62%",end: "top 0"
          }
        }) 
        tl_color
          .from("#simply-type .simply-color-cover", {duration: 0.7,opacity: 0,y: 10, ease: "ease-in",}, "-=0.5")

        let tl_bg = gsap.timeline({
          scrollTrigger: {trigger: "#simply-type .bge", markers: true, start: "top 50%", end: "top 0"}
        }) 
        tl_bg
          .from("#simply-type .bge", {duration: 0.7,autoAlpha: 0,y: 100, ease: "ease-in",}, "-=0.5")


        let tl_photoFooter = gsap.timeline({
            scrollTrigger: {trigger: "#simply-photo-footer",markers: false,start: "top 58%",end: "top 0"}
        }) 
        tl_photoFooter
          .from("#simply-photo-footer .fr", {duration: 0.5,opacity: 0,x: -70, ease: "ease-in",}, "-=0.4")
          .from("#simply-photo-footer .se", {duration: 0.5,opacity: 0,y: -70, ease: "ease-in",}, "-=0.4")
          .from("#simply-photo-footer .th", {duration: 0.5,opacity: 0,y: 70, ease: "ease-in",}, "-=0.4")
          .from("#simply-photo-footer .logo1", {duration: 0.5,opacity: 0,x: 70, ease: "ease-in",}, "-=0.4")
          .from("#simply-photo-footer .logo2", {duration: 0.5,opacity: 0,x: -70, ease: "ease-in",}, "-=0.4")

        let tl_next = gsap.timeline({
            scrollTrigger: {trigger: "#simply-next",markers: false, start: "top 90%",end: "top 0"}
        }) 
        tl_next
          .from("#simply-next .next", {duration: 0.7,autoAlpha: 0,y: -70, ease: "ease-in",}, "-=0.4")
          .from("#simply-next .i1", {duration: 0.7,autoAlpha: 0, y: 70, ease: "ease-in",}, "-=0.4")
          .from("#simply-next .i2", {duration: 0.7,autoAlpha: 0, y: -70, ease: "ease-in",}, "-=0.4")
    }  
    
  
   //   animations()
    
    
    useEffect(() => {

      
      
        let tl_loading = gsap.timeline()
        tl_loading
          .fromTo("#simply-main .logo", {duration: 0.5,autoAlpha: 0,y: 30,ease: "ease-in-out",},{autoAlpha: 1, y:0}, "+=0.5")
       
      $(window).scroll(function (){

        if($("#simply-salon .logo").length > 0) {
          // console.log("simpla-salon")
          let real = $(window).height();
          let te1 = $("#simply-about .text-prvy").offset().top;
          let te2 = $("#simply-about .text-druhy").offset().top;
          let role = $("#simply-about .role").offset().top;
          let credits = $("#simply-about .credits").offset().top;
          let obr1 = $("#simply-about .pho1-simply").offset().top;
          let obr2 = $("#simply-about .pho2-simply").offset().top;
          let type = $("#simply-type .type").offset().top;
          let abeceda = $("#simply-type .abeceda").offset().top;
          let palleta = $("#simply-type .color-paleta").offset().top;
          let color_cover = $("#simply-type .simply-color-cover").offset().top;
          let simply_type = $("#simply-type").offset().top;
          let bge = $("#simply-type .bge").offset().top;
          let photoFooter =  $("#simply-photo-footer").offset().top;
          


          if($(window).scrollTop() > te1 - real/1.2) {
            $("#simply-about .text-prvy").addClass("top")
          }
          if($(window).scrollTop() > te2 - real/1.1) {
            $("#simply-about .text-druhy").addClass("top")
          }
          if($(window).scrollTop() > role - real/1.3) {
            $("#simply-about .role").addClass("left")
          }
          if($(window).scrollTop() > credits - real/1.5) {
            $("#simply-about .credits").addClass("left")
          }
          if($(window).scrollTop() > credits - real/1.5) {
            $("#simply-about .pho1-simply").addClass("left")
          }
          if($(window).scrollTop() > credits - real/1.5) {
            $("#simply-about .pho2-simply").addClass("right")
          }      
          if($(window).scrollTop() > type - real/1.2) {
            $("#simply-type .type").addClass("left")
          }
          if($(window).scrollTop() > abeceda - real/1.2) {
            $("#simply-type .abeceda").addClass("right")
          }
          if($(window).scrollTop() > palleta - real/1.2) {
            $("#simply-type .color-paleta").addClass("left")
          }
          if($(window).scrollTop() > palleta - real/1.4) {
            $("#simply-type .simply-color-cover").addClass("top")
          }
          if($(window).scrollTop() > bge - real/1.7) {
            $("#simply-type .bge").addClass("top")
          }
          if($(window).scrollTop() > photoFooter - real/2) {
            $("#simply-photo-footer .fr").addClass("left")
            $("#simply-photo-footer .se").addClass("top")
            $("#simply-photo-footer .th").addClass("top")
            $("#simply-photo-footer .logo1").addClass("left")
            $("#simply-photo-footer .logo2").addClass("right")
          }

          let debug = $(document).height() - $(window).height() - 50;
          if($(window).scrollTop() >= debug) {
            // $(".f1").addClass("top")
            // $(".f2").addClass("top")
            // $(".f3").addClass("top")
            // $(".f4").addClass("top")
          }


          

        }
        

      })
     
      
      
       

    }, [])
    
   


   //  window.onscroll = () => {
   //     console.log($(".bge").offset().top)

   //     $real = $(window).height();
   //     $current = $(window).scrollTop();

   //     if($(window).scrollTop() > ($story01 - $real*3/4)){
   //       $(".story01").addClass("story-active");
   //      }

   //  }
   




  

    return (
      
       <>
          <div id="simply-salon">
            <Navbar logo={logo} color={"sal_txt"} work_bold={"bold"} about_bold={"normal"}/>
            <Main />
            <About />
            <Type />
            <PhotoFooter />
            <Next next="na-caji" previous="tilia" />
            <Footer color={"txt"} cl={"simply"}/>
          </div>
       </> 
          
    
    )
}

export default Simply_Salon


