import React from 'react'
import ilu from "../../../../assets/na-caji/ilu.png"

const Ilustration = () => {
    return (
       <div id="na-caji-ilustration" className="mt-2.5 pb-2 xl:pb-8 xl:mt-6" >
         <p className="text-2xs text-black font-sans text-center xl:text-sm" style={{letterSpacing:"3px"}} >ILLUSTRATION</p>  
         <img src={ilu} alt="img" className="w-full mx-auto  px-9 xl:mt-5 xl:w-10/12" />   
       </div>
    )
}

export default Ilustration
