import React, {useEffect, useRef} from 'react'
import logo from "../../../../assets/logo-simply.svg"
import ilu1 from "../../../../assets/ilustracie1.svg"
import "aos/dist/aos.css";


const Main = () => {

  

   const logoRef = useRef(null)


   useEffect(() => {
      
     

     

   }, [])


    return (
        <div id="simply-main" className="bg-sal_bg w-full h-screen 2xl:h-screen relative" >  
          <div className="h-full flex justify-center align-center" >
            <img ref={logoRef}  src={logo} alt="logo" className="logo w-52 sm:w-72 md:w-85 lg:w-96 xl:w-96 2xl:w-150" />
          </div>
          <img src={ilu1} alt="ilu1" className="ilu1 lg:w-150 xl:w-205 2xl:w-210 z-20"/>
        </div>
    )
}

export default Main
