import React, {useState} from 'react'
import { Link } from "react-router-dom"


const MobileNavbar = () => {

    // const [barActive, setBarActive] = useState(false);
    // const [barFull, setbarFull] = useState("full");
    // const [barOpacity, setbarOpacity] = useState("0");
    // const [open, setOpen] = useState(false)

    // const handleBar = () => {
    //     setBarActive(!barActive)
    //     if(barFull === "full") {
    //       setbarFull("0")
    //       setbarOpacity("100")
    //       setOpen(true)
    //       document.body.classList.add("h")
    //     }else {
    //       setbarFull("full")
    //       setbarOpacity("0")
    //       setOpen(false)
    //       document.body.classList.remove("h")
    //     }
    //   }

    const [mobileActive, setMobileActive] = useState(false)
    const [open, setOpen] = useState(false)

 
    const handleMobileActive = () => {

      setMobileActive(!mobileActive)

      if(!mobileActive) {
        setOpen(true)
        document.body.classList.add("h")
    
      }else {
        setOpen(false)
        document.body.classList.remove("h")
       
      }


    }

    return (
        <div id="mobile-navbar" className={open ? "lg:hidden open" : "lg:hidden"} >  
           <div onClick={handleMobileActive} className={mobileActive ? `bar active` : "bar"} >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul className="flex h-screen justify-center items-center flex-col" >
              <li className="text-white font-bold font-skuso text-4xl my-5 p-1" ><a href="#" ><Link to="/" >Work</Link></a></li>
              <li className="text-white font-bold font-skuso text-4xl my-5 p-1" ><a href="#" ><Link to="/about" >About</Link></a></li>
            </ul>
        </div>
    )
}

export default MobileNavbar
