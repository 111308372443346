import React from 'react'
import bg1 from "../../../../assets/red-basket/bg-1.png"
import bg2 from "../../../../assets/red-basket/bg-2.png"
import bg3 from "../../../../assets/red-basket/bg-3.png"


const Bgs = () => {
    return (
        <div id="red-basket-bgs" >
            <img src={bg1} alt="img" className="rb1 w-full mt-3 md:mt-5 lg:mt-6 xl:mt-7" />  
            <img src={bg2} alt="img" className="rb2 w-full mt-3 md:mt-5 lg:mt-6 xl:mt-7" />  
            <img src={bg3} alt="img" className="rb3 w-full mt-3 md:mt-5 lg:mt-6 xl:mt-7" />      
        </div>
    )
}

export default Bgs
