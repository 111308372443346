import React from 'react'
import v1 from "../../../../assets/na-caji/veronika1.png"
import v2 from "../../../../assets/na-caji/veronika3.png"
import v3 from "../../../../assets/na-caji/veronika2.png"

const Veronika = () => {
    return (
        <div id="na-caji-veronika" className="pb-10 xl:pt-9 xl:pb-16 2xl:pt-12 2xl:pb-20" >
            <div className="grid gap-y-2 lg:gap-y-0 justify-center gridik-ver" >
                <img src={v1} alt="img" className="poh1 w-10/12 mx-auto lg:w-11/12  xl:w-full mt-8" />  
                <img src={v2} alt="img" className="poh2 w-10/12 mx-auto lg:w-11/12  xl:w-full mt-8" />  
                <img src={v3} alt="img" className="poh3 w-10/12 mx-auto lg:w-11/12  xl:w-full mt-8" />  
            </div>
        </div>
    )
}

export default Veronika
