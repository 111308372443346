import React from 'react'
import logo from "../../../../assets/slido/main-slido-logo.svg"

const Main = () => {
    return (
        <div id="slido-main" className="w-full h-screen bg-sli_bg" >
          <div className="h-full flex justify-center align-center" >
            <img src={logo} alt="img" className="logo w-36 sm:w-36 md:w-40 lg:w-48 xl:w-52 2xl:w-56" />
          </div>
        </div>
    )
}

export default Main
