import React from 'react'
import img1 from "../../../../assets/simply-footer1.png"
import img2 from "../../../../assets/simply-footer2.png"
import img3 from "../../../../assets/simply-footer3.png"
import vector from "../../../../assets/photo-footer.svg"
import logo2 from "../../../../assets/logo-salon.svg"
import logo from "../../../../assets/logo-salon2.svg"

const PhotoFooter = () => {
    return (
        <div id="simply-photo-footer" className="bg-sal_bg mt-10 py-16 lg:py-20 xl:py-32 relative lg:flex justify-center items-start" >
           <img src={vector} alt="img" className="footer-vector z-0" />
            <div className="w-10/12 sm:w-80 lg:w-auto mx-auto relative lg:mt-12 2xl:mx-16" >
               <img src={img1} alt="img" className=" fr z-20 relative lg:w-80 xl:w-auto" />    
               <img src={logo} alt="img" className="logo1 w-2/5 sm:w-28 lg:w-32 mt-8 absolute left-1/3 xl:w-auto"  />    
            </div>
            <div className="w-10/12 sm:w-96 md:w-10/12 lg:w-auto mx-auto mt-32 md:mt-36 lg:mt-0 2xl:mx-16" >
               <img src={logo2} alt="img" className="logo2 w-2/5 sm:w-28 lg:w-32 mx-auto relative -left-10 xl:w-auto" />    
               <div className="w-full mx-auto md:flex md:mx-0 justify-center items-start md:mt-5" >
                  <img src={img2} alt="img" className=" se mt-5 md:mt-0 z-20 lg:w-52 xl:w-auto" />    
                  <img src={img3} alt="img" className="th mt-7 md:mt-3 z-20 lg:w-52 xl:w-auto" />    
               </div>           
           </div>
        </div>
    )
}

export default PhotoFooter
