import React from 'react'
import l1 from "../../../../assets/tilia/l1.png"
import l2 from "../../../../assets/tilia/l2.png"
import l3 from "../../../../assets/tilia/l3.png"


const TypeLust = () => {
    return (
        <div id="tilia-type-luster" className="pb-10" >
          <div className="flex justify-center items-center flex-col mt-16 lg:mt-10 lg:flex-row" >

            <div className="l1 mt-10" >
                <img src={l1} alt="l1" />  
                <h3 className="text-center mt-2 lg:mt-5 xl:mt-7 text-sm lg:text-base" >CEILING 3</h3>
            </div>
            <div className="l2 mt-10" >
                <img src={l2} alt="l2" />  
                <h3 className="text-center mt-10 lg:mt-5 xl:mt-7 text-sm lg:text-base" >FLOOR</h3>
            </div>
            <div className="l3 mt-10" >
                <img src={l3} alt="l3" />  
                <h3 className="text-center mt-2  lg:mt-5 xl:mt-7 text-sm lg:text-base" >CEILING 5</h3>
            </div>


          </div>
         
        </div>
    )
}

export default TypeLust
