import React from 'react'
import shapes from "../../../../assets/red-basket/shapes.svg"
import "../../../App.css"

const Shapes = () => {
    return (
        // <div id="red-basket-shapes" className="w-full h-auto py-20 lg:mt-20" >
        //    <div className="shapes relative" >
        //      <div className="shapik mx-auto py-40" >
        //         <div className="flex w-52 mx-auto" >
        //           <div>
        //             <img src={d} className="absolute -mt-28 -ml-12" />
        //             <img src={line} className="absolute -mt-14 z-30 -ml-5" />
        //             <img src={red} className="z-10" />
        //           </div>
        //           <div className="ml-4" >
        //             <p className="absolute -mt-28 -ml-8 text-xs" >from the logo</p> 
        //             <img src={line} className="absolute -mt-14 z-30 -ml-5" />
        //             <img src={basket} className="z-10" />
        //           </div>
        //         </div>     
        //      </div>
        //      <div className="shapik mx-auto py-40" >
        //       <p className="absolute -mt-20  text-xs" >from the logo</p> 
        //        <div className="shapik2-content flex items-center justify-center" >
        //           <div></div>
        //           <div>
        //             <img src={otocka} />
        //           </div>
        //           <div></div>
        //           <div>
        //             <img src={nanu} />
        //           </div>
        //        </div>          
        //      </div>
        //      <div className="shapik mx-auto py-40" >
        //       <p className="absolute -mt-20  text-xs" >kets can have different sizes </p> 
        //        <div className="shapik3-content flex items-end justify-center" >
        //           <div></div>
        //           <div></div>
        //           <div></div>
        //           <div></div>
        //        </div>          
        //      </div>
        //      <div className="shapik mx-auto py-32" >
        //        <div className="shapik4-content flex items-center justify-center flex-col" >
        //           <img src={mfavi} />
        //           <div className="posl mt-10" ></div>
        //        </div>          
        //      </div>
        //    </div> 
        // </div>
        // <div id="red-basket-shapes" className="w-full h-auto py-28" >
        //   <div id="shapes-container" >


        //     <div id="shape" className="" >
        //       <div className="content-text h-full flex justify-center items-center" >
        //         <div className="relative" >
        //            <div className="absolute -mt-16 -ml-6" >
        //             <img src={d} className="absolute -mt-10 -ml-5" />
        //             <img src={line}  />
        //            </div>
        //            <img src={red} className="w-12" />
        //         </div>
        //         <div className="relative ml-2" >
        //           <div className="absolute -mt-16 -ml-6" >
        //             <p className="text-2xs absolute -mt-10 -ml-5" >from the logo</p> 
        //             <img src={line} />
        //           </div>
        //            <img src={basket} className="w-20" />
        //         </div>
        //       </div>
        //     </div>


        //     <div id="shape" className="" >
        //       <div className="content-shapes  h-full flex justify-center items-center" >
        //         <div className="scale-50" ></div>
        //         <img className="" src={otocka} />
        //         <div className="" ></div>
        //         <img className="" src={nanu} />
        //         <div className="absolute" >
        //            <p className="" >for basket</p> 
        //         </div>
        //       </div>
        //     </div>


        //     <div id="shape"  className="">
        //       <div className="content-shapes h-full flex justify-center items-end" >
        //         <div className="" ></div>
        //         <div className="" ></div>
        //         <div className="" ></div>
        //         <div className="" ></div>
        //         <div className="absolute" >
        //            <p className="" >kets can have different sizes </p> 
        //         </div>
        //       </div>
        //     </div>


        //     <div id="shape" className="" >
        //       <div className="content-main-symbol h-full flex justify-center items-center flex-col" >
        //         <div>
        //           <img src={mfavi} />
        //         </div>
        //         <div className="" ></div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        <div id="red-basket-shapes" className="w-full h-auto py-12 xl:py-16" >
          <div >
            <img src={shapes} alt="img" className="bg w-11/12 lg:w-10/12 xl:w-10/12 mx-auto" />
          </div>
        </div>
    )
}

export default Shapes
