import React from 'react'
import d1 from "../../../../assets/tilia/desk-1.png"
import d2 from "../../../../assets/tilia/desk-2.png"
import d3 from "../../../../assets/tilia/desk-3.png"
import d4 from "../../../../assets/tilia/desk-4.png"

const Desk = () => {
    return (
        <div id="tilia-desk" className="py-10 lg:py-0" >
         <div className="w-10/12 md:w-96 mx-auto grid justify-center gap-y-6 gridik-desk" >
            <img src={d1} alt="tilia-desk" className="des1" />
            <img src={d2} alt="tilia-desk" className="des2" />
            <img src={d3} alt="tilia-desk" className="des3" />
            <img src={d4} alt="tilia-desk" className="des4" />
         </div>
        </div>
    )
}

export default Desk
