import React from 'react'

const Text = ({text, top}) => {
    return (
        <h2 className={`text-in-about text-content text-secondary font-serif text-2xl mt-${top} z-20 relative xl:text-2extra 2xl:text-5-2extra xl:leading-9`} >
          {text} 
        </h2>
    )
}

export default Text
