import React from 'react'
import Photo from './sub-content/Photo'
import Text from './sub-content/Text'
import ilu2 from "../../../../assets/ilustracie2.svg"

const About = () => {
    return (
        <div id="simply-about" className="relative pb-10 lg:pb-16 xl:pb-24" >
          <Text />
          <Photo />  
          <img src={ilu2} alt="img" className="ilu2 z-0  lg:w-210 absolute top-10 left-10 -ml-10 2xl:left-1/4  hidden lg:block" />   
        </div>
    )
}

export default About
