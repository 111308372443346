import React from 'react'

const Photo = ({img}) => {
    return (
        <div class="photo relative z-20" >
           <img src={img}  className="pho  w-full" /> 
        </div>
    )
}

export default Photo
