import React from 'react'

import PhotoContent from './photo/PhotoContent'
import TextContent from './text/TextContent'
// lg:flex lg:justify-center lg:items-start h-auto flex-row py-10 lg:py-0

const AboutMain = () => {
    return (
        <div id="about-main" className="lg:px-20 xl:px-20 2xl:px-24" >
           <TextContent />
           <PhotoContent />
        </div>
    )
}

export default AboutMain
