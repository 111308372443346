import React from 'react'


const TextContent = ({f_t, s_t, f_st, f_tt, s_st, s_tt}) => {

   

    return (
        <div id="text-content" className="w-10/12 lg:w-8/12 mx-auto lg:flex justify-center items-start xl:justify-start lg:px-10 2xl:justify-center" >
          <div id="txt1" className="lg:w-9/12 xl:w-170 xl:text-simply-cover" >  
             <p  className="text-prvy lg:w-11/12 xl:w-full font-sans text-sal_txt font-normal text-base lg:text-sm xl:text-sm " >{f_t}</p>
             <p  className="text-druhy lg:w-11/12 xl:w-full mt-8 lg:mt-6 xl:mt-9  font-sans text-sal_txt font-normal text-base lg:text-sm xl:text-sm " >{s_t}</p>
          </div>
          <div id="txt2" className=" role mt-10 lg:mt-0 xl:w-60 xl:ml-10" >
             <div  className="role mt-5 lg:mt-0">
                <h4 className="font-bold text-sal_txt font-sans  pb-2" >{f_st}</h4>
                <p className="" >{f_tt}</p>
             </div>
             <div  className="credits mt-5 lg:mt-7" >
                <h4 className="font-bold text-sal_txt font-sans pb-2" >{s_st}</h4>
                <p className="" >{s_tt}</p>
             </div>
          </div>
        </div>
    )
}

export default TextContent
