import React, {useEffect} from 'react'
import Navbar from '../../Navbar/Navbar'
import Main from './content/Main'
import Text from "./content/Text"
import Nastenka from './content/Nastenka'
import Colory from './content/Colory'
import Vizitka from './content/Vizitka'
import Ilustration from './content/Ilustration'
import Social from './content/Social'
import Foto from './content/Foto'
import Poster from './content/Poster'
import Veronika from './content/Veronika'
import Podcast from './content/Podcast'
import Next from "../../Next/Next"
import Footer from "../../Footer/Footer"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import $ from "jquery"


const Na_Caji = () => {

  gsap.registerPlugin(ScrollTrigger)



  useEffect(() => {
  let tl_loading = gsap.timeline()
  tl_loading
    .fromTo("#na-caji .logo", {duration: 0.5,autoAlpha: 0,y: 30,ease: "ease-in-out",},{autoAlpha: 1, y:0}, "+=0.5")
    
    $("#na-caji-vizitka .bg").addClass("ani_top_70")
    $("#na-caji-nastenka .bg").addClass("ani_top_70")
    $("#na-caji-podcast .bg").addClass("ani_top_70")
    $("#na-caji-ilustration").addClass("ani_top_70")
    $("#na-caji-social").addClass("ani_top_70")
    $("#na-caji-poster").addClass("ani_top_70")
    $("#na-caji-colory #colory").addClass("ani_opacity")
    $("#na-caji-colory .te1").addClass("ani_left_50")
    $("#na-caji-colory .te2").addClass("ani_right_50")
    $("#na-caji-colory .te3").addClass("ani_right_50")
    $("#na-caji-colory .te4").addClass("ani_left_50")

    
  
      $(window).scroll(function (){
      
        if($("#na-caji-main .logo").length > 0) {  
          let real = $(window).height();
          let te1 = $("#na-caji #text .text-prvy").offset().top;
          let te2 = $("#na-caji #text .text-druhy").offset().top;
          let role = $("#na-caji #text .role").offset().top;
          let credits = $("#na-caji #text .credits").offset().top;
          let vizitka = $("#na-caji-vizitka").offset().top;
          let nastenka = $("#na-caji-nastenka").offset().top;
          let podcast = $("#na-caji-podcast").offset().top;
          let ilustration = $("#na-caji-ilustration").offset().top;
          let poster = $("#na-caji-poster").offset().top;
          let colory = $("#na-caji-colory #colory").offset().top;
          let co_te1 = $("#na-caji-colory .te1").offset().top;
          let co_te2 = $("#na-caji-colory .te2").offset().top;
          let social = $("#na-caji-social").offset().top;
          let pho1 = $("#na-caji-foto .poh1").offset().top;
          let pho2 = $("#na-caji-foto .poh2").offset().top;
          let pho3 = $("#na-caji-foto .poh3").offset().top;
          let v_pho1 = $("#na-caji-veronika .poh1").offset().top;
          let v_pho2 = $("#na-caji-veronika .poh2").offset().top;
          let v_pho3 = $("#na-caji-veronika .poh3").offset().top;
          

        
       

          if($(window).scrollTop() > te1 - real/1.2) {
            $("#na-caji #text .text-prvy").addClass("top")
          }
          if($(window).scrollTop() > te2 - real/1.1) {
            $("#na-caji #text .text-druhy").addClass("top")
          }
          if($(window).scrollTop() > role - real/1.3) {
            $("#na-caji #text .role").addClass("top")
          }
          if($(window).scrollTop() > credits - real/1.3) {
            $("#na-caji #text .credits").addClass("top")
          }
          if($(window).scrollTop() > vizitka - real/1.1) {
            $("#na-caji-vizitka .bg").addClass("top")
          }
          if($(window).scrollTop() > nastenka - real/1.1) {
            $("#na-caji-nastenka .bg").addClass("top")
          }
          if($(window).scrollTop() > podcast - real/1.1) {
            $("#na-caji-podcast .bg").addClass("top")
          }
          if($(window).scrollTop() > ilustration - real/1.1) {
            $("#na-caji-ilustration").addClass("top")
          }
          if($(window).scrollTop() > colory - real/1.1) {
            $("#na-caji-colory #colory").addClass("top")
          }
          if($(window).scrollTop() > co_te1 - real/1.1) {
            $("#na-caji-colory .te1").addClass("left__right_margin")
          }
          if($(window).scrollTop() > co_te2 - real/1.1) {
            $("#na-caji-colory .te2").addClass("left__right_margin")
          }
          if($(window).scrollTop() > co_te1 - real/1.1) {
            $("#na-caji-colory .te3").addClass("left__right_margin")
          }
          if($(window).scrollTop() > co_te2 - real/1.1) {
            $("#na-caji-colory .te4").addClass("left__right_margin")
          }
          if($(window).scrollTop() > social - real/1.1) {
            $("#na-caji-social").addClass("top")
          }
          if($(window).scrollTop() > pho1 - real/1.3) {
            $("#na-caji-foto .poh1").addClass("top")
          }
          if($(window).scrollTop() > pho2 - real/1.3) {
            $("#na-caji-foto .poh2").addClass("top")
          }
          if($(window).scrollTop() > pho3 - real/1.3) {
            $("#na-caji-foto .poh3").addClass("top")
          }
          if($(window).scrollTop() > poster - real/1.3) {
            $("#na-caji-poster").addClass("top")
          }
          if($(window).scrollTop() > v_pho1 - real/1.3) {
            $("#na-caji-veronika .poh1").addClass("top")
          }
          if($(window).scrollTop() > v_pho2 - real/1.3) {
            $("#na-caji-veronika .poh2").addClass("top")
          }
          if($(window).scrollTop() > v_pho3 - real/1.3) {
            $("#na-caji-veronika .poh3").addClass("top")
          }

       
        }


      })


  }, [])

    return (
        <div id="na-caji" >
          <Navbar color={"sal_txt"} work_bold={"bold"} about_bold={"normal"}/>   
          <Main />
          <Text />  
          <Nastenka />
          <Colory />
          <Vizitka />
          <Ilustration />
          <Social />
          <Foto />
          <Poster />
          <Veronika />
          <Podcast />
          <Next next="slido" previous="simply-salon" />
          <Footer cl={"slido"} />
        </div>
    )
}

export default Na_Caji
