import React, { useEffect } from 'react'
import Navbar from '../../Navbar/Navbar'
import logo from "../../../assets/logo3.svg"
import Book from './content/Book'
import Bridge from './content/Bridge'
import Comunication from './content/Comunication'
import Ilustration from './content/Ilustration'
import List from './content/List'
import Main from './content/Main'
import Man from './content/Man'
import Merch from './content/Merch'
import Text from './content/Text'
import Footer from "../../Footer/Footer"
import Next from '../../Next/Next'
import $ from "jquery"
import gsap from "gsap"

const Slido = () => {


  useEffect(() => {

    let tl_loading = gsap.timeline()
    tl_loading
      .fromTo("#slido-main .logo", {duration: 0.5,autoAlpha: 0,y: 30,ease: "ease-in-out",},{autoAlpha: 1, y:0}, "+=0.5")


    $("#slido-book").addClass("ani_top_70")
    $("#slido-comunicaion").addClass("ani_top_70")
    $("#slido-bridge").addClass("ani_top_70")
    $("#slido-list").addClass("ani_top_70")
    $("#slido-man").addClass("ani_top_70")
    $("#slido-ilustration").addClass("ani_top_70")
    $("#slido-merch .m1").addClass("ani_top_70")
    $("#slido-merch .m2").addClass("ani_top_70")
    $("#slido-merch .m3").addClass("ani_top_70")



    $(window).scroll(function (){
      
      if($("#slido-main .logo").length > 0) {
        let real = $(window).height();
        let te1 = $("#slido #text .text-prvy").offset().top;
        let te2 = $("#slido #text .text-druhy").offset().top;
        let role = $("#slido #text .role").offset().top;
        let credits = $("#slido #text .credits").offset().top;
        let book = $("#slido-book").offset().top;
        // let comunicaion = $("#slido-comunicaion").offset().top;
        // let bridge =  $("#slido-bridge").offset().top;
        // let list = $("#slido-list").offset().top;
        // let man = $("#slido-man").offset().top;
        // let ilustration = $("#slido-ilustration").offset().top;
        // let m1 = $("#slido-merch .m1").offset().top;
        // let m2 = $("#slido-merch .m2").offset().top;
        // let m3 = $("#slido-merch .m3").offset().top;


     
        if($(window).scrollTop() > te1 - real/1.2) {
          $("#slido #text .text-prvy").addClass("top")
        }
        if($(window).scrollTop() > te2 - real/1.2) {
          $("#slido #text .text-druhy").addClass("top")
        }
        if($(window).scrollTop() > role - real/1.2) {
          $("#slido #text .role").addClass("left__right_margin")
        }
        if($(window).scrollTop() > credits - real/1.2) {
          $("#slido #text .credits").addClass("left__right_margin")
        }
        if($(window).scrollTop() > book - real/1.2) {
          $("#slido-book").addClass("top")
        }
      //   if($(window).scrollTop() > comunicaion - real/1.2) {
      //     $("#slido-comunicaion").addClass("top")
      //   }
      //   if($(window).scrollTop() > bridge - real/1.2) {
      //     $("#slido-bridge").addClass("top")
      //   }
      //   if($(window).scrollTop() > list - real/1.2) {
      //     $("#slido-list").addClass("top")
      //   }
      //   if($(window).scrollTop() > man - real/1.2) {
      //     $("#slido-man").addClass("top")
      //   }
      //   if($(window).scrollTop() > ilustration - real/1.2) {
      //     $("#slido-ilustration").addClass("top")
      //   }
      //   if($(window).scrollTop() > m1 - real/1.2) {
      //     $("#slido-merch .m1").addClass("top")
      //   }
      //   if($(window).scrollTop() > m2 - real/1.2) {
      //     $("#slido-merch .m2").addClass("top")
      //   }
      //   if($(window).scrollTop() > m3 - real/1.2) {
      //     $("#slido-merch .m3").addClass("top")
      //   }
      }

    })  

  }, [])


    return (
        <>
           <div id="slido" >
             <Navbar logo={logo} color={"sal_txt"} work_bold={"bold"} about_bold={"normal"}/>   
             <Main />
             <Text />
             <Book />
             {/* <Comunication />
             <Bridge />
             <List />
             <Man />
             <Ilustration />
             <Merch /> */}
             <Next next={"red-basket"} previous={"na-caji"} />
             <Footer cl={"slido"} />
           </div>
        </>
    )
}

export default Slido
