import React from 'react'
import TextContent from '../../TextContent'

const Text = () => {

    const text = {
      f_t: "Tilia connects new technology and Slovak culture through a unique design for a light fixture. Design typology allows for multiple variations of the design. The national tree of Slovakia is the lime tree and provided inspiration the design. The source of light appears to be in the midst of a growth cycle. The light shield, which features traditional basket weaving, is shaped like a leaf. The source of light and the light shield connect in the same way that tree branches do - this conection was inspiration for logo design.",
      s_t: "",
      f_st: "Role:",
      f_tt: "Art Director",
      s_st: "Credits",
      s_tt: "Rendering: Silvia Bohušová",
    }

    return (
        <div id="text" className="pt-20 pb-12" >
          <TextContent 
            f_t={text.f_t}
            s_t={text.s_t}
            f_st={text.f_st}
            f_tt={text.f_tt}
            s_st={text.s_st}
            s_tt={text.s_tt}
          />
        </div>
    )
}

export default Text