import React from 'react'
import pod from "../../../../assets/na-caji/podcast4.jpg"

const Podcast = () => {
    return (
        <div id="na-caji-podcast" className="mt-0" >
           <img src={pod} alt="img" className="bg w-full" /> 
        </div>
    )
}

export default Podcast
