import React from 'react'
import TextContent from '../../TextContent'

const Text = () => {

    const text = {
      f_t: "Slido is the industry-leading audience interaction platform. It helps users to get the most out of meetings and events by crowdsourcing top questions and engaging participants with live polls and quizzes.",
      s_t: "From internal communications professionals to team leaders, conference organizers, and individual presenters, Slido is used by anyone looking to enable open conversation at a live or virtual meeting.",
      f_st: "Role:",
      f_tt: "Graphic design, merch design, illustration",
      s_st: "Credits:",
      s_tt: "Logo design & Typography: GoBigname studioDesign director: Adam CigánikGraphic design: Viktor Surovčík",
    }

    return (
        <div id="text" className="py-20" >
          <TextContent 
            f_t={text.f_t}
            s_t={text.s_t}
            f_st={text.f_st}
            f_tt={text.f_tt}
            s_st={text.s_st}
            s_tt={text.s_tt}
          />
        </div>
    )
}

export default Text
