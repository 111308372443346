import React from 'react'
import f1 from "../../../../assets/na-caji/foto1.png"
import f2 from "../../../../assets/na-caji/foto2.png"
import f3 from "../../../../assets/na-caji/foto3.png"

const Foto = () => {
    return (
        <div id="na-caji-foto" className="pb-10 xl:pt-9 xl:pb-16 2xl:pt-12 2xl:pb-20" >
            <div className="grid gap-y-2 lg:gap-y-0 justify-center gridik-foto" >
                <img src={f1} alt="img" className="poh1 w-10/12 mx-auto lg:w-11/12  xl:w-full mt-8" />  
                <img src={f2} alt="img" className="poh2 w-10/12 mx-auto lg:w-11/12  xl:w-full mt-8" />  
                <img src={f3} alt="img" className="poh3 w-10/12 mx-auto lg:w-11/12  xl:w-full mt-8" />  
            </div>
        </div>
    )
}

export default Foto
