import React from 'react'
import TextContent from '../../../TextContent'

const Text = () => {

    const text = {
      f_t: "After spending several years in Prague at various famous hair salons, Tatiana wanted to start her own. She moved back to her hometown, Orava, Slovakia, and founded Simply Salon.",
      s_t: "Simply Salon’s name reflects the accessibility of their highest quality services, which include styling, cutting, dying, and more. The S-monogram combines the shape of wavy hair, water droplets, and strands of hair being cut.",
      f_st: "Role:",
      f_tt: "Art directior, Illustrator",
      s_st: "Credits:",
      s_tt: "Photography: Ján Kacko ",
    }


    return (
        <div className="pt-10 lg:pt-20" >
          <TextContent 
            f_t={text.f_t}
            s_t={text.s_t}
            f_st={text.f_st}
            f_tt={text.f_tt}
            s_st={text.s_st}
            s_tt={text.s_tt}
          />
        </div>
    )
}

export default Text
