import React from 'react'
import bg from "../../../../assets/obalky2.svg"

const Type = () => {

  return (
    <>
      <div id="simply-type" className="bg-sal_bg2 pt-12 relative md:pt-24" >
        <h4  style={{ zIndex: 100 }} className="type text-sal_txt text-center text-xs md:text-base" >TYPE</h4>
        <h2  style={{ zIndex: 100 }} className="abeceda text-center text-lg mt-6 md:text-3xl font-serif tracking-widest md:mt-16" >ABCDEFGHIJKLMNO<br />PRSTUVWXYZ</h2>
        <div className="mt-8 md:mt-20" >
          <h4 className="color-paleta z-30 text-sal_txt text-center text-xs md:text-base" >COLOR PALETTE</h4>
          <div className="simply-color-cover flex justify-center items-center mt-5 md:mt-10">
            <div className="w-1/5 h-6 md:h-8 xl:h-12 xl:w-64  mx-2" ></div>
            <div className="w-1/5 h-6 md:h-8 xl:h-12 xl:w-64 mx-2" ></div>
            <div className="w-1/5 h-6 md:h-8 xl:h-12 xl:w-64 mx-2" ></div>
            <div className="w-1/5 h-6 md:h-8 xl:h-12 xl:w-64 mx-2" ></div>
          </div>
        </div>

        <img src={bg} alt="img" className="bge mt-16 mx-auto" />

      </div>
        </>
    )
}

export default Type
