import React from 'react'
import logo from "../../../../assets/tilia/logo-tilia.svg"

const Main = () => {
    return (
        <div id="tilia-main" className="w-full h-screen" >
          <div className="h-full flex justify-center align-center" >
            <img src={logo} alt="logo" className="logo w-20 md:w-24 lg:w-28 xl:w-32 2xl:w-36" />
          </div>
        </div>
    )
}

export default Main
