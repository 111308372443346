import React from 'react'
import TextContent from '../../TextContent'

const Text = () => {

    const text = {
      f_t: "Red Basket helps large companies meet their content needs.",
      s_t: "Their brand imagery includes an adaptable U-shape. This represents the various creative ideas and solutions they provide for their clients. The bold colors symbolize their forward-thinking mindset and their willingness to dive into even the toughest of topics",
      f_st: "Role:",
      f_tt: "Artdirector",
      s_st: "",
      s_tt: "",
    }

    return (
        <div id="text" className="py-20" >
          <TextContent 
            f_t={text.f_t}
            s_t={text.s_t}
            f_st={text.f_st}
            f_tt={text.f_tt}
            s_st={text.s_st}
            s_tt={text.s_tt}
          />
        </div>
    )
}

export default Text