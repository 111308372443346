import React from 'react'
import book from "../../../../assets/slido/all3.jpg"

const Book = () => {
    return (
        <div id="slido-book" className="" >
           <img src={book} alt="img" className="w-full" /> 
        </div>
    )
}

export default Book
