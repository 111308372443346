import React from 'react'
import poster from "../../../../assets/na-caji/poster.png"

const Poster = () => {
    return (
        <div id="na-caji-poster" className="mt-0" >
           <img src={poster} alt="img" className="w-full" /> 
        </div>
    )
}

export default Poster
