import React from 'react'
import star from "../../../../assets/start.svg"
import Text from './Text'


const TextContent = () => {

    const text = {
        fi: "The best brands have creative personalities. However, achieving a look that epitomizes your brand can be difficult.",
        se: "I collaborate with brands to help them discover their personality and then communicate it through branding, illustration, and design. I get excited about colors and shapes because I know the potential they have to shape a brand and make it as successful as possible."
    }

    return (
        <div id="text-content" className="w-5/6 sm:w-5/6 md:w-120 mx-auto lg:mx-0 2xl:w-150 3xl:w-200 pt-16 md:pt-20 lg:pt-0" >  
            <div className="relative tex1" >
               <Text text={text.fi}  />
            </div>
            <div className="relative tex2" >
               <Text text={text.se} top={10}  />
               {/* <img src={star} alt="img" className="absolute -right-2 xl:-right-3 3xl:right-24 2xl:-top-6 2xl:w-24 -top-8 z-10 w-16 xl:w-20" /> */}
            </div>
        </div>
    )
}

export default TextContent  