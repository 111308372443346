import React from 'react'
import Background from '../../../Background/Background'
import PhotoFooter from './PhotoFooter'

const PhotoText = () => {

    const fi_list = ["Brand Identity", "Illustration", "Art Direction", "Social media design", "Editiorial Design", "Package Design", "Consulting"]
    const se_list = ["Drop me a line at hello@zuzanakopanicova.sk and I’ll get back to you ASAP."]
    return (
        <div id="photo-text" className="" >
           <div className="mt-10 xl:mt-12 2xl:mt-16 3xl:mt-20">     
            <h4 className="creative-about" >creative services</h4>
            <div className="services flex justify-between mt-4 xl:mt-5" >
                <div>
                    <p>Brand Identity</p>  
                    <p>Illustration</p>  
                    <p>Art Direction</p>  
                    <p>Social media design</p>  
                </div>
                <div className="px-2" >
                    <p>Editiorial Design</p>  
                    <p>Package Design</p>  
                    <p>Consulting</p>  
                </div>
            </div>
           </div>
           <div className="mt-20 xl:mt-24 2xl:mt-36 3xl:mt-40" >     
            <h4 className="creative-about" >Want to get in touch?</h4>
            <div className="mt-4 xl:mt-5" >
                <div className="email-contact" >
                    <p>Drop me a line at</p>  
                    <p><a className="link-email" href="mailto:hello@zuzanakopanicova.sk" >hello@zuzanakopanicova.sk</a></p>  
                    <p>and I'll get back to you ASAP.</p>  
                </div>
            </div>
           </div>
        </div>
    )
}

export default PhotoText
