import React from 'react'
import Color from './Color'

const Colory = () => {

    const colors = [
      {color: "#E84918", hex: "#e84918", RGB: "232 73 24"},
      {color: "#614998", hex: "#614998", RGB: "97 73 152"},
      {color: "#36B7B9", hex: "#36b7b9", RGB: "54 183 185"},
      {color: "#F9C9BC", hex: "#f9c9bc", RGB: "249 201 188"},
      {color: "#E8E4F3", hex: "#e8e4f3", RGB: "232 228 243"},
      {color: "#B7DFE5", hex: "#b7dfe5", RGB: "183 223 229"},
    ]


    return (
        <div id="red-basket-colory" className="pb-20" >
           <div className="pt-2" >
             <h4 className="t1 text-xs text-center mt-4 xl:text-sm" >COLORS & TYPE</h4>
             <h3 className="t2 text-base font-semibold text-center text-red_tx mt-5 md:mt-7 md:text-lg xl:text-xl" style={{letterSpacing: "5px"}} >INTER</h3>
             <h1 className="t3 text-center font-semibold text-2xl mt-6 md:text-3xl md:mt-10 xl:text-4xl lg:mt-12" style={{letterSpacing:"5px"}} >ABCDEFGHIJKLMNO<br />PRSTUVWXYZ</h1>
           </div> 
           <div className="mt-16 lg:mt-28 grid justify-center gap-y-16 lg:gap-y-20 gridik-color" >
             {colors.map((color) => (
               <Color {...color} />
             ))}
           </div>
        </div>
    )
}

export default Colory
