import React from 'react'
import social from "../../../../assets//na-caji/social4.jpg"

const Social = () => {
    return (
        <div id="na-caji-social" className="mt-3 relative w-full" >
           <h3 className="font-sans text-1xs absolute text-white left-1/2 top-1.5 font-thin lg:text-sm lg:top-5" style={{letterSpacing:"2px"}} >SOCIAL MEDIA</h3> 
           <img src={social} alt="img" className="bg w-full" />  
        </div>
    )
}

export default Social