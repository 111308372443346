import React from 'react'
import nastenka from "../../../../assets/na-caji/nastenka4.jpg"

const Nastenka = () => {
    return (
        <div id="na-caji-nastenka" className="" >
           <img src={nastenka} alt="img"  className="bg w-full" />   
        </div>
    )
}

export default Nastenka
