import React from 'react'
import patern from "../../../../assets/red-basket/patern.svg"

const Patern = () => {
    return (
        <div id="red-basket-patern" className="w-full h-auto bg-red_tx pb-16 md:pb-20 xl:pt-2 xl:pb-24" >
           <h3 className="text-center text-white text-2xs md:text-xs md:py-10 lg:py-12 py-8 xl:text-sm" style={{letterSpacing:"2px"}} >PATTERN</h3>
           <div>
            <img src={patern} alt="img" className="w-8/12 lg:w-7/12 mx-auto" />
           </div>  
        </div>
    )
}

export default Patern
