import React from 'react'
import about_img from "../../../../assets/about.png"
import Background from '../../../Background/Background'
import Photo from './Photo'
import PhotoText from './PhotoText'

const PhotoContent = () => {
    return (
        <div id="photo-content" className="photo-content mx-auto lg:mx-0 w-5/6 h-auto  mt-14 sm:w-4/6 md:w-100 lg:mt-0  xl:w-85 2xl:w-100 3xl:w-150"  >
           <Photo img={about_img} />
           <PhotoText />
        </div>
    )
}

export default PhotoContent
