import React from 'react'
import { useHistory } from "react-router-dom"

const Select = ({title, img, width, resp, right, top}) => {


  const history = useHistory()


  const handleMouseEnter = (e) => {
    e.target.nextElementSibling.classList.add("visi")
  }
  const handleMouseLeave = (e) => {
    e.target.nextElementSibling.classList.remove("visi")
  }
  const handleMouseMove = (e) => {
    e.target.nextElementSibling.style.right = e.clientX * .01 + right + "%"
    e.target.nextElementSibling.style.top = e.clientY * -0.09 - 5 + top + "%"
  }

  const handleClick = (e) => {
    
    if(e.target.innerHTML === "Simply Salon") {
      history.push("/simply-salon")
    }else if(e.target.innerHTML === "Na Čaji") {
      history.push("/na-caji")
    }else if(e.target.innerHTML === "Slido") {
      history.push("/slido")
    }else if(e.target.innerHTML === "Red Basket") {
      history.push("/red-basket")
    }else if(e.target.innerHTML === "Tilia") {
      history.push("/tilia")
    }


  }


    // onMouseMove={handleMouseMove} 
    // onMouseEnter={handleMouseEnter} 
    // onMouseLeave={handleMouseLeave} 
  return (
      <div className={`${resp} select-my  w-auto`} >
          <div className="flex items-center" >
            <h2 
              className="z-30 cursor-pointer" 
              id="select-item" 
              onMouseMove={handleMouseMove} 
              onMouseEnter={handleMouseEnter} 
              onMouseLeave={handleMouseLeave}
              onClick={handleClick} >
              {title} 
               </h2>
            <img src={img} className="image -ml-5 lg:-ml-8 xl:-ml-24 2xl:-ml-20" />
          </div>
      </div>
  )
}

export default Select
