import React from 'react'
import img1 from "../../../../../assets/simply_img2.png"
import img2 from "../../../../../assets/simply_img1.png"
import ilu2 from "../../../../../assets/ilustracie2.svg"


const Photo = () => {


    return (
      <div id="photo-simply" className="mt-10 lg:mt-20 lg:flex lg:w-10/12 lg:mx-auto relative justify-center" >
          <img src={ilu2} alt="img" className="ilu2 w-96 z-0 md:w-150 md:-top-28 lg:left-0 lg:w-200 absolute -top-20 left-0 lg:hidden" />   
          <div className="w-11/12 mx-auto lg:mx-2 lg:w-auto md:w-10/12" >
            <img src={img1} alt="img" className="pho1-simply w-full z-10 relative" />
          </div>
          <div className="w-11/12  mx-auto mt-8 lg:mt-0 lg:mx-2 lg:w-auto md:w-10/12 xl:ml-5">
             <img  src={img2} alt="img" className="pho2-simply w-full z-10 relative" />
          </div>  
        </div>
    )
}

export default Photo
