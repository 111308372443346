import React from 'react'
import final from "../../../../assets/tilia/final.png"

const Final = () => {
    return (
        <div id="tilia-final" >
            <img src={final} alt="final" className="w-full" />    
        </div>
    )
}

export default Final
