import React from 'react'
import right_sipka from "../../assets/next/right-sipka.svg"
import left_sipka from "../../assets/next/left-sipka.svg"
import {  useHistory } from "react-router-dom"
import "./Next.css"

const Next = ({previous,next}) => {

    const history = useHistory();

    const handleNext = () => {
      history.push(`/${next}`)
    }
    const handlPrevious = () => {
      history.push(`/${previous}`)
    }

    return (
        <div id="next" className="w-full flex justify-between items-center px-5 mt-5 pb-5 sm:px-10 sm:mt-6 md:mt-8 lg:px-12 lg:pb-10 xl:pb-20 xl:mt-10 xl:px-12 2xl:px-24" >
           <div className="left flex items-center justify-center cursor-pointer flex-row-reverse" onClick={handlPrevious} >
           <h3 className="font-serif text-2xs md:text-sm text-black font-bold px-2 xl:text-base" >PREVIOUS PROJECT</h3>
             <svg width="76" height="16" viewBox="0 0 76 16"  className="w-8  md:w-10 xl:w-14"  fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <path d="M74.5339 7.32404L10.7894 7.32404C10.6166 7.33747 10.4547 7.42558 10.3365 7.57056C10.2182 7.71553 10.1525 7.90655 10.1525 8.10497C10.1525 8.30339 10.2182 8.4944 10.3365 8.63938C10.4547 8.78435 10.6166 8.87247 10.7894 8.8859L74.5339 8.8859C74.6273 8.89316 74.721 8.87835 74.8092 8.84239C74.8974 8.80643 74.9782 8.75009 75.0466 8.67686C75.115 8.60364 75.1695 8.51509 75.2068 8.41671C75.2441 8.31833 75.2633 8.21222 75.2633 8.10497C75.2633 7.99771 75.2441 7.89161 75.2068 7.79323C75.1695 7.69485 75.115 7.6063 75.0466 7.53307C74.9782 7.45984 74.8974 7.4035 74.8092 7.36754C74.721 7.33158 74.6273 7.31678 74.5339 7.32404Z" fill="#1D1D1B"/>
              <path d="M12.7763 0.333034L1.02405 8.10646L12.7763 15.8799L12.7763 0.333034Z" fill="#1D1D1B"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="76" height="16" fill="white" transform="matrix(1 0 0 -1 0 16)"/>
              </clipPath>
              </defs>
            </svg>
             
           </div> 
           <div className="right flex items-center justify-center cursor-pointer" onClick={handleNext} >
             <h3 className="font-serif text-2xs md:text-sm text-black font-bold px-2 xl:text-base" >NEXT PROJECT</h3>
             <svg width="76" height="16" className="w-8 md:w-10 xl:w-14" viewBox="0 0 76 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.46623 8.67596H65.2107C65.3835 8.66253 65.5454 8.57442 65.6636 8.42944C65.7819 8.28447 65.8477 8.09345 65.8477 7.89503C65.8477 7.69661 65.7819 7.5056 65.6636 7.36062C65.5454 7.21565 65.3835 7.12753 65.2107 7.1141H1.46623C1.37283 7.10684 1.27913 7.12165 1.19093 7.15761C1.10274 7.19357 1.02193 7.24991 0.953529 7.32314C0.885132 7.39636 0.830597 7.48491 0.793312 7.58329C0.756027 7.68167 0.736786 7.78778 0.736786 7.89503C0.736786 8.00229 0.756027 8.10839 0.793312 8.20677C0.830597 8.30515 0.885132 8.3937 0.953529 8.46693C1.02193 8.54016 1.10274 8.5965 1.19093 8.63246C1.27913 8.66842 1.37283 8.68322 1.46623 8.67596Z" fill="#1D1D1B"/>
              <path d="M63.2238 15.667L74.9761 7.89354L63.2238 0.120117V15.667Z" fill="#1D1D1B"/>
             </svg>

           </div> 
        </div>
    )
}

export default Next
