import React from 'react'
import logo from "../../../../assets/red-basket/logo.svg"

const Main = () => {
    return (
        <div id="red-basket-main" className="w-full h-screen bg-secondary2" >
          <div className="h-full flex justify-center align-center" >
            <img src={logo} alt="img" className="logo w-40 sm:w-48 md:w-52 lg:w-64 xl:w-80" />
          </div>
        </div>
    )
}

export default Main
